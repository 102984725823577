import { Component, OnInit, NgZone } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/helpers/users.service';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pointused',
  templateUrl: './pointused.component.html',
  styleUrls: ['./pointused.component.scss'],
})
export class PointusedComponent implements OnInit {

    // data client
    no_client: string;
    client_name: string;
    hp: string;
    email: string;
    cardtype: string;
    address1: string;
    city: string;
    religion: string;
    pekerjaan: string;
    zipcode: string;
    gender: string;
    dt_birth: string;
    branch: string;
    status_vip: string;
    ktp: string;
  
    // Point history
    pointHistory = [];
    totalPointHistory = 0;
  
    // data layout
    page = 1;
  
  constructor(
    private user: UserService,
    private modalController: ModalController,
    private zone: NgZone,
    private routers: Router,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.getPointHistory();
  }

    // Point History
    getPointHistory() {
      const noClient = this.user.getno_client();
      this.api.getCustomerPointHistory(noClient, this.page)
      .subscribe((r) => {
        if (r.status === 200) {
          this.pointHistory = r.data;
          this.totalPointHistory = r.total_point;
        }
      });
    }
    
}
